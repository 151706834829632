import React, { useEffect, useState, Fragment } from 'react';
import Divider from "./Divider";
import metrika from "./metrika";

function Main() {
  const [ name, setName ] = useState('');

  useEffect(() => {
    
  },[]);

  const changeName = (e) => {
    setName(e.target.value);
  }

  const enterForm = (e) => {
    e.preventDefault();
    if(!name) {
      alert('Введите имя');
      return;
    }
    document.enterForm.submit();
    metrika({'chaosys': {'ник в форму' : name}})
  }

  return (
    <div className="Main">
      <header>
        <div className="Main_logo"></div>
      </header>
      <Divider />
      <div className="Main_enter">
        <div className="Main_enter_wrap">
          <h2>Добро пожаловать</h2>
          <div className="Main_enter_wrap_desc">
            Игра по мотивам средневековых войн с небольшой долей магии.<br/>
            В игре существует 4 вида скиллов: инстинкт, ловкость, стремительность и отражение.<br/>
            Четыре склонности: тьма, хаос, свет и порядок. Все со своими крутыми обилками.
          </div>
          <form name="enterForm">
            <input name='user' className="Main_input_enter" placeholder="Введите имя" onChange={changeName} autocomplete="off" />
            <a href="#" onClick={enterForm} className="Main_input_enter_button">
              <span class="button-game-bg-left"></span>
              <span class="button-game-bg-mid">
                <span>Войти</span>
              </span>
              <span class="button-game-bg-right"></span>
            </a>
          </form>
        </div>
      </div>
      <Divider />
      <footer>© 2020 by chaosys.ru</footer>
    </div>
  );
}

export default Main;
