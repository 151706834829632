import React, { useEffect, useState } from 'react';
import './Logs.css';

function Logs(props) {

  useEffect(() => {
  },[]);

  return (
  	<div className="Logs">
	    {props.logs.map((log) => log)}
    </div>
  );
}

export default Logs;
