import React, { useEffect, useState } from 'react';
import Logs from './Logs';

function Battle(props) {

  const [attackTarget, setAttackTarget] = useState(null);
  const [secureTarget, setSecureTarget] = useState(null);

  const attackTargetChange = (e) => {
    setAttackTarget(e.target.value);
  };

  const secureTargetChange = (e) => {
    setSecureTarget(e.target.value);
  };

  const fireBattle = (e) => {
    e.preventDefault(); 
    props.sendMess(attackTarget, secureTarget);
  };

  useEffect(() => {
  },[]);

  return (
    <div className="Battle">
      <div className="battle-column">
        <div>
          <p className="battle-column_title">Защита</p>
          <label>
            <input type="radio" value="head"
                   checked={secureTarget === 'head'}
                   onChange={secureTargetChange} />
            <div className="feik_input"></div>
            Голова
          </label>
          <label>
            <input type="radio" value="grud"
                   checked={secureTarget === 'grud'}
                   onChange={secureTargetChange} />
            <div className="feik_input"></div>
            Грудь
          </label>
          <label>
            <input type="radio" value="givot"
                   checked={secureTarget === 'givot'}
                   onChange={secureTargetChange} />
            <div className="feik_input"></div>
            Живот
          </label>
          <label>
            <input type="radio" value="poys"
                   checked={secureTarget === 'poys'}
                   onChange={secureTargetChange} />
            <div className="feik_input"></div>
            Пояс
          </label>
          <label>
            <input type="radio" value="nogi"
                   checked={secureTarget === 'nogi'}
                   onChange={secureTargetChange} />
            <div className="feik_input"></div>
            Ноги
          </label>
        </div>
        <div>
          <p className="battle-column_title">Атака</p>
          <label>
            <input type="radio" value='head'
                   checked={attackTarget === 'head'}
                   onChange={attackTargetChange} />
            <div className="feik_input"></div>
            Голова
          </label>
          <label>
            <input type="radio" value="grud"
                   checked={attackTarget === 'grud'}
                   onChange={attackTargetChange} />
            <div className="feik_input"></div>
            Грудь
          </label>
          <label>
            <input type="radio" value="givot"
                   checked={attackTarget === 'givot'}
                   onChange={attackTargetChange} />
            <div className="feik_input"></div>
            Живот
          </label>
          <label>
            <input type="radio" value="poys"
                   checked={attackTarget === 'poys'}
                   onChange={attackTargetChange} />
            <div className="feik_input"></div>
            Пояс
          </label>
          <label>
            <input type="radio" value="nogi"
                   checked={attackTarget === 'nogi'}
                   onChange={attackTargetChange} />
            <div className="feik_input"></div>
            Ноги
          </label>
        </div>
        <div>
          <a 
            className={"battle-button " + (props.gameOver ? 'hide' : '')}
            href="#"
            onClick={fireBattle}
          >
          </a>
        </div>
      </div>
      <Logs logs={props.logs} setLogs={props.setLogs} />
    </div>
  );
}

export default Battle;
