import React from 'react';
const Divider = () => {
  return (
    <div className="Divider">
        <hr className="Divider-rule" />
    </div>
  );
};

export default Divider;
