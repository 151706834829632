import React, { useEffect, useState } from 'react';
import './Person.css';

function Person(props) {

  useEffect(() => {
  },[]);

  return (
  	<div className="Person_wrap">
	    { props.user ?
	    	<div className="Person">
				<div className="person-name">{props.user.name}<span>[1]</span></div>
				<div className="person-live">
					<div className="person-live-line-wrap">
						<div className="person-live-line" style={{width: Math.round(props.user.life * 100 / 100)+'%'}}></div>
					</div>
					<div className="person-live-value">
						{props.user.life}/{100}
					</div>
				</div>
			
				<div className="person-ikip">
					<div className="person-ikip-left">
						<div className="person-ikip-elem person-ikip-left-helm"></div>
						<div className="person-ikip-elem person-ikip-left-weapon"></div>
						<div className="person-ikip-elem person-ikip-left-armor"></div>
						<div className="person-ikip-elem person-ikip-left-belt"></div>
					</div>
					<div className="person-ikip-center">
						<div className="person-ikip-elem person-ikip-center-figure"></div>
					</div> 
					<div className="person-ikip-right">
						<div className="person-ikip-elem person-ikip-right-earrings"></div>
						<div className="person-ikip-elem person-ikip-right-collar"></div>
						<div className="person-ikip-elem person-ikip-right-gloves"></div>
						<div className="person-ikip-elem person-ikip-right-weapon"></div>
						<div className="person-ikip-elem person-ikip-right-boots"></div>
					</div>
					</div>
				</div>
    		:
    		<div className="Person_wait">
      			Ожидайте соперника
	    	</div>
	    }
    </div>
  );
}

export default Person;
