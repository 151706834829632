import React, { useEffect, useState, Fragment } from 'react';
import io from "socket.io-client";
import Person from "./Person";
import Battle from "./Battle";
import Main from "./Main";
import Chat from "./Chat";
import Divider from "./Divider";
import metrika from "./metrika";
import './App.css';
const socket = io('https://chaosys.ru:3001');

const parseQuery = (queryString) => {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || 'noname');
    }
    return query;
}

const getPhase = (name, hit, life, attack, apponent) => {

  const body = {
    head: 'голову',
    grud: 'грудь',
    givot: 'живот',
    poys: 'пояс',
    nogi: 'ноги'
  }

  if(hit) {
    return <div className="Logs-attack">{name} бьет {apponent} в {body[attack]}. <span>-{hit}</span> [{life}/100]</div>
  }
  return <div className="Logs-block">{name} бьет {apponent} в {body[attack]}. Но попадает в блок. [{life}/100]</div>
}


function App() {
  const [ user, setUser ] = useState(window.location.search && { name: parseQuery(window.location.search).user, life: 100 });
  const [ opponent, setOpponent ] = useState(null);
  const [ wait, setWait ] = useState(false);
  const [ logs, setLogs ] = useState([]);
  const [ gameOver, setGameOver ] = useState(false);

  const sendMess = (attack, secure) => {
    if(attack && secure) {
      socket.emit('battle', {
        user: user.name,
        life: user.life,
        attack,
        secure
      });  
      setWait(true);
      metrika({'chaosys': {'бой': 'атака'}})
    } else {
      alert('Выберите место для защиты и атаки')
      metrika({'chaosys': {'бой': 'не выбрана защита или атака'}})
    }
  };
  useEffect(() => {
    socket.on('getUser', function(cb) {
      if (user) cb(user.name) //userName is unique
    });
    socket.on('battle', function(userData, apponentData) {
      //{name: "Spamol", hit: 15, life: 76, attack: "grud"}
      const arr = [];
      if (userData.name === user.name) {
        setUser({name: userData.name, life: userData.life});
        setOpponent({name: apponentData.name, life: apponentData.life});
      } else {
        setUser({name: apponentData.name, life: apponentData.life});
        setOpponent({name: userData.name, life: userData.life});
      }
      arr.push(getPhase(userData.name, userData.hit, apponentData.life, userData.attack, apponentData.name));
      if (apponentData.life) arr.push(getPhase(apponentData.name, apponentData.hit, userData.life, apponentData.attack, userData.name));
      const dieUser = !userData.life ? userData.name : apponentData.name;
      if (!apponentData.life || !userData.life) {
        arr.push(<div className="Logs-grey">{dieUser} повержен</div>);
        setGameOver(true);
        metrika({'chaosys': 'бой окончен'})
      }
      setLogs(arr);
      setWait(false);
    });
    socket.on('opponent', function(data) {
      if(data) {
        setOpponent({ name: data, life: 100 });
        metrika({'chaosys': {'присоединился оппонент': data}})
      } else {
        setOpponent(null);
        setUser({ name: user.name, life: 100 });
      }
    });
    metrika({'chaosys': 'заход на главную'})
  },[]);

  return (
    <div className="App">
      { user ?
        
          <Fragment>
            <div className="Location">
              <h2>Дуэль</h2>
              <div className="Location_wrap">
                {!wait ?
                  <Fragment>
                    <Person user={user}/>
                    <Battle sendMess={sendMess} logs={logs} gameOver={gameOver} />
                    <Person user={opponent}/>
                  </Fragment>
                :
                  <div className="wait-block-wrap">
                    <div className="wait-block wait-block_mine">
                      <div className="wait-block-name">{user.name}<span>[1]</span></div>
                      <div className="person-live">
                        <div className="person-live-line-wrap">
                          <div className="person-live-line" style={{width: Math.round(user.life * 100 / 100)+'%'}}></div>
                        </div>
                        <div className="person-live-value">
                          {user.life}/{100}
                        </div>
                      </div>
                    </div>
                    <div className="wait-block">
                      <div className="wait-block-name">{opponent.name}<span>[1]</span></div>
                      <div className="person-live">
                        <div className="person-live-line-wrap">
                          <div className="person-live-line" style={{width: Math.round(opponent.life * 100 / 100)+'%'}}></div>
                        </div>
                        <div className="person-live-value">
                          {opponent.life}/{100}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <Divider />
            <Chat />
          </Fragment>
      :
        <Main />
      }
    </div>
  );
}

export default App;
