import React, { useEffect, useState } from 'react';
import './Chat.css';

function Chat(props) {

  useEffect(() => {
  },[]);

  return (
  	<div className="Chat">
	    Чат
    </div>
  );
}

export default Chat;
